<template>
	<file-pond
		:label-idle="filePondOptions.labelIdle || 'Drop file here...'"
		name="image"
		:allow-multiple="filePondOptions.uploadMultiple"
		:acceptedFileTypes="filePondOptions.acceptedFiles"
		:server="filePondOptions.server"
		:maxFileSize="filePondOptions.maxFileSize"
		:allowRevert="filePondOptions.allowRevert"
		:stylePanelLayout="filePondOptions.stylePanelLayout"
		:imageResizeTargetWidth="filePondOptions.imageResizeTargetWidth"
		:allowImagePreview="filePondOptions.allowImagePreview"
		@processfile="onProcessfile"
	/>
</template>

<script>
import { mergeDeep } from '@/plugins/utils';
import { FilePond } from '@/plugins/filepond';

export default {
	name: 'ImageUpload',
	props: ['settings'],
	data: () => {
		return {
			default: {
				uploadMultiple: false,
				acceptedFiles: ['image/*'],
				server: {
					process: {
						withCredentials: true,
					},
					revert: null
				},
				maxFileSize: '2MB',
				allowRevert: true,
				stylePanelLayout: null,
			},
		};
	},
	computed: {
		filePondOptions() {
			return mergeDeep({
				server: {
					process: {
						onload: (response) => {
							try {
								response = JSON.parse(response)
								this.$emit('upload', response)
							} catch(error) {
								console.error('file-pond.server.onload', error)
							}
						}
					}
				}
			}, this.default, this.settings || {});
		},
	},
	methods: {
		onProcessfile(...args) {
			
		}
	},
	components: {
		FilePond,
	},
};
</script>

import vueFilePond from 'vue-filepond';
import FilePondPluginImageExifOrientation from 'filepond-plugin-image-exif-orientation';
import FilePondPluginImageTransform from 'filepond-plugin-image-transform';
import FilePondPluginImagePreview from 'filepond-plugin-image-preview';
import FilePondPluginFileValidateSize from 'filepond-plugin-file-validate-size';
import FilePondPluginFileValidateType from 'filepond-plugin-file-validate-type';
import FilePondPluginImageResize from 'filepond-plugin-image-resize';

import 'filepond/dist/filepond.min.css';
import 'filepond-plugin-image-preview/dist/filepond-plugin-image-preview.css';

export const FilePond = vueFilePond(
    FilePondPluginImageExifOrientation, 
    FilePondPluginImageTransform, 
    FilePondPluginImagePreview, 
    FilePondPluginFileValidateSize, 
    FilePondPluginFileValidateType, 
    FilePondPluginImageResize
);
<template>
	<div>
		<div class="row">
			<div class="col-md-6">
                <div class="card">
                    <div class="card-body">
                        <h4 class="header-title mb-3">Загрузить изображение профиля</h4>
                        <image-upload :settings="filePondOptions" @upload="imageUploaded" />
                    </div>
                </div>
			</div>

			<div class="col-md-6">
				<div class="card">
					<div class="card-body">
						<h4 class="header-title mb-3">Настройки профиля</h4>

						<div class="row">
							<div class="col-md-12">
								<div class="form-group">
									<label for="firstname">Имя</label>
									<input type="text" v-model="user.name" class="form-control" id="firstname" placeholder="Введите имя" />
								</div>
							</div>
						</div>
						<!-- end row -->

						<div>
							<button v-if="!loading" type="submit" v-on:click="saveData()" class="btn btn-primary mt-2">Сохранить</button>
							<button v-else class="btn btn-success mt-2" type="button" disabled>
								<span class="spinner-border spinner-border-sm mr-1" role="status" aria-hidden="true"></span>
								Загрузка...
							</button>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>
<script>
import { mapState } from 'vuex';
import { sleep } from '@/plugins/utils'
import ImageUpload from '@/components/upload/Image.vue';

export default {
	name: 'SettingsProfile',
	data: () => {
		return {
            loading: false,
			form: {}
		};
	},
	mounted() {
        this.form = Object.assign({}, this.user)
	},
	computed: {
		...mapState({
			user: state => state.auth.user,
			settings: state => state.settings,
		}),
		filePondOptions() {
			return {
				server: {
					url: this.settings.backend_url + '/media/image/upload?type=user_picture',
				},
				allowImagePreview: false,
				maxFileSize: null,
				imageResizeUpscale: false,
				imageResizeTargetWidth: 400
			};
		},
	},
	methods: {
		imageUploaded(data) {
			this.user.profile_image = data.image
		},
		async saveData() {
			if(this.loading) return false

			this.loading = true
			var [err, response] = await this.$orm.mutate({
				user: {
					update: {
						where: { id: { _eq: this.user.id } },
						_set: {
							name: this.user.name
						}
					}
				}
			})
			if(err) {
                log.error('methods.saveData', '$orm.mutate', err)
                return $.NotificationApp.send("Ошибка", err.message, "top-right", "", "error")
			}
			
			await sleep(150)
			this.loading = false
		}
    },
    components: {
        ImageUpload
    }
};
</script>
<style>
.filepond--root .filepond--drop-label {
	min-height: 4.5em;
	color: black;
}
</style>
